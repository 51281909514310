import React from 'react';
import './App.css';
import { Menu, Sidebar, Icon } from 'semantic-ui-react';
import HomePage from './components/Homepagelayout';


const App = () => {
  // const [visible, setVisible] = useBooleanKnob({ name: 'visible' })

  return <HomePage />; //(
  //   <div className="App">
  //     <Menu>
  //       <Menu.Item><Menu.Header>General</Menu.Header> </Menu.Item>
  //       <Menu.Item as='a'>
  //         <Icon name='home' />
  //         Home
  //     </Menu.Item>
  //       <Menu.Item as='a' right>
  //         Homeaa
  //     </Menu.Item>
  //     </Menu>
  //     <div className="ui sidebar inverted vertical menu sidebar-menu" id="sidebar">
  //       <div className="item">xxx</div>
  //     </div>

  //     <header className="App-header">

  //       Learn React

  //     </header>
  //   </div >
  // );
}

export default App;
